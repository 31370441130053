@import '../../../variables';

.card {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 600px) {
        min-height: 256px;
    }
}

.list {
    width: 100%;
    margin: 0;
    padding: ($gutter-width / 2);

    @media (min-width: 600px) {
        margin: 50px;
        padding: 0px;
    }
}

.headlineStat {
    margin-bottom: $gutter-width;
    text-align: center;
}

.statRow {
    display: flex;
    justify-content: space-around;
    text-transform: uppercase;
    margin: 0;

    @media (min-width: 960px) {
        justify-content: space-between;
    }
}

.statSignup {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.statColumns {
    flex-direction: column;
    text-align: center;
}

.signupHeading {
    color: #757575;
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 14px;
}

.employeeIcons {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-top: 20px;

    svg {
        width: 100%;
    }
}