@import '../../variables';
@import '../../helpers';

.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginForm {
    font-size: 16px;
    width: 360px;

    @media (min-width: 600px) {
        height: 70%;
    }
}

.loginWrapper {
    margin: 55px 20px 90px;

    @media (min-width: 600px) {
        margin: 35px auto 90px;
    }
}
